export const data = [
    {
        id: 1,
        title: 'القهوة الساخنة',
        image: '../../../img/list/coffe-cup.png',
        selected: true,
        items: [
            {
                id: 1,
                title: 'سبانيش لاتيه',
                description: '',
                image: '../../img/food/span.jpg',
                price: 15,
                calories: 209,
                selected: false,
            },
            {
                id: 2,
                title: 'بستاشو لاتيه',
                description: '',
                image: '../../img/food/pstachio.png',
                price: 15,
                calories: 180,
                selected: false,
            },
            {
                id: 3,
                title: 'لاتيه',
                description: '',
                image: '../../img/food/zafran.png',
                price: 15,
                calories: 151,
                selected: true,
            },
            {
                id: 4,
                title: 'كابتشينو',
                description: '',
                image: '../../img/food/capatchino.png',
                price: 15,
                calories: 144,
                selected: true,
            },
            {
                id: 5,
                title: 'كورتادو',
                description: '',
                image: '../../img/food/late.png',
                price: 15,
                calories: 162,
                selected: true,
            },
            {
                id: 6,
                title: 'ريد فيلفت',
                description: '',
                image: '../../img/food/falt.png',
                price: 15,
                calories: 131,
                selected: true,
            },
            {
                id: 7,
                title: 'فلات وايت',
                description: '',
                image: '../../img/food/falt.png',
                price: 15,
                calories: 131,
                selected: true,
            },
            {
                id: 8,
                title: 'موكا ساخن',
                description: '',
                image: '../../img/food/moka.png',
                price: 15,
                calories: 215,
                selected: true,
            },
            {
                id: 9,
                title: 'أمريكانو',
                description: '',
                image: '../../img/food/amrico.png',
                price: 15,
                calories: 12,
                selected: true,
            },
            {
                id: 10,
                title: 'بلاك كوفي',
                description: '',
                image: '../../img/food/amrico.png',
                price: 12,
                calories: 12,
                selected: true,
            },
            {
                id: 11,
                title: 'ماكياتو',
                description: '',
                image: '../../img/food/maciato.png',
                price: 15,
                calories: 90,
                selected: true,
            },
            {
                id: 12,
                title: 'دبل إسبرسو',
                description: '',
                image: '../../img/food/ispressop.png',
                price: 12,
                calories: 8,
                selected: true,
            },
            {
                id: 13,
                title: 'قهوة تركية',
                description: '',
                image: '../../img/food/turkish.png',
                price: 10,
                calories: 5,
                selected: true,
            },
            {
                id: 14,
                title: 'قهوة فرنسية بالبندق',
                description: '',
                image: '../../img/food/cortado.png',
                price: 15,
                calories: 103,
                selected: true,
            },
            {
                id: 15,
                title: 'قهوة فرنسية',
                description: '',
                image: '../../img/food/cortado.png',
                price: 12,
                calories: 7,
                selected: true,
            },
            {
                id: 15,
                title: 'v60',
                description: '',
                image: '../../img/items.png',
                price: 15,
                calories: 0,
                selected: true,
            },
            {
                id: 25,
                title: 'امريكانو',
                description: '',
                image: '../../img/items.png',
                price: 15,
                calories: 0,
                selected: true,
            },
            {
                id: 17,
                title: 'هوت شوكلت',
                description: '',
                image: '../../img/items.png',
                price: 15,
                calories: 0,
                selected: true,
            },
            {
                id: 16,
                title: 'دلة قهوة عربية',
                description: '',
                image: '../../img/food/dlah.png',
                price: 25,
                calories: 6,
                selected: true,
            },
        ]
    },
    {
        id: 2,
        title: 'القهوة الباردة',
        image: '../../../img/list/bard.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'آيس سبانيش لاتيه',
                description: '',
                image: '../../img/food/spanish.png',
                price: 15,
                calories: 206,
                selected: false,
            },
            {
                id: 2,
                title: 'آيس لاتيه',
                description: '',
                image: '../../img/food/saffron.png',
                price: 15,
                calories: 250,
                selected: false,
            },
            {
                id: 3,
                title: 'آيس كراميل لاتيه',
                description: '',
                image: '../../img/food/fanila.png',
                price: 15,
                calories: 206,
                selected: true,
            },
            {
                id: 4,
                title: 'آيس كراميل مكياتو',
                description: '',
                image: '../../img/food/caramel.png',
                price: 17,
                calories: 227,
                selected: true,
            },
            {
                id: 5,
                title: ' ’آيس موكا',
                description: '',
                image: '../../img/food/icemoca.png',
                price: 15,
                calories: 240,
                selected: true,
            },
            {
                id: 6,
                title: 'آيس وايت موكا',
                description: '',
                image: '../../img/food/fanila.png',
                price: 15,
                calories: 227,
                selected: true,
            },
            {
                id: 7,
                title: 'آيس أمريكانو',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 15,
                selected: true,
            },
            {
                id: 8,
                title: 'آيس ريد فيلفيت',
                description: '',
                image: '../../img/food/redf.jpeg',
                price: 15,
                calories: 204,
                selected: true,
            },
            {
                id: 9,
                title: 'آيس بستاشيو',
                description: '',
                image: '../../img/food/bstachio.png',
                price: 15,
                calories: 227,
                selected: true,
            },
            {
                id: 10,
                title: 'ايس كراميل لاتيه',
                description: '',
                image: '../../img/food/caramel.png',
                price: 15,
                calories: 227,
                selected: true,
            },
            {
                id: 11,
                title: 'آيس كابتشينو',
                description: '',
                image: '../../img/food/ice-kabtchno.png',
                price: 15,
                calories: 227,
                selected: true,
            },
            {
                id: 12,
                title: 'فرابتشينو كارميل',
                description: '',
                image: '../../img/food/Frappuccino.png',
                price: 15,
                calories: 227,
                selected: true,
            },
            {
                id: 13,
                title: 'فرابتشينو شوكلاتة',
                description: '',
                image: '../../img/food/Chocolaty.png',
                price: 15,
                calories: 227,
                selected: true,
            },
            {
                id: 14,
                title: 'آيس v60',
                description: '',
                image: '../../img/food/ice-v60.png',
                price: 15,
                calories: 227,
                selected: true,
            },
            {
                id: 15,
                title: 'آيس كيمكس',
                description: '',
                image: '../../img/food/ice-kmks.png',
                price: 15,
                calories: 227,
                selected: true,
            },
        ]
    },
    {
        id: 3,
        title: 'القهوة المختصة',
        image: '../../../img/list/mo5.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'كيمكس',
                description: '',
                image: '../../img/food/kimks.jpeg',
                price: 15,
                calories: 0,
                selected: false,
            },
            {
                id: 3,
                title: 'V60',
                description: '',
                image: '../../img/food/v60.jpeg',
                price: 15,
                calories: 0,
                selected: true,
            },
        ]
    },
    {
        id: 4,
        title: 'الشاي',
        image: '../../../img/list/tea.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'شاي أحمر',
                description: 'كوب - براد',
                image: '../../img/food/shahya7mr.png',
                price: '5-15',
                calories: '',
                selected: false,
            },
            {
                id: 2,
                title: 'شاي أخضر',
                description: 'كوب - براد',
                image: '../../img/food/shahya5dr.png',
                price: '5-15',
                calories: '',
                selected: false,
            },
            {
                id: 3,
                title: 'شاي كرك - عدني',
                description: 'كوب - براد',
                image: '../../img/food/adni.png',
                price: '5-20',
                calories: '',
                selected: false,
            },
            {
                id: 4,
                title: 'شاي أنجليزي',
                description: 'كوب - براد',
                image: '../../img/food/englishtea.png',
                price: '5-20',
                calories: '',
                selected: false,
            },
            {
                id: 5,
                title: 'شاي مغربي',
                description: 'كوب - براد',
                image: '../../img/food/mghrbi.png',
                price: '5-20',
                calories: '',
                selected: false,
            },
            {
                id: 6,
                title: 'ينسون',
                description: 'كوب - براد',
                image: '../../img/food/yanson.png',
                price: '5-20',
                calories: '',
                selected: false,
            },
            {
                id: 7,
                title: 'كركدية',
                description: 'كوب - براد',
                image: '../../img/items.png',
                price: '5-20',
                calories: '',
                selected: false,
            },
        ]
    },
    {
        id: 5,
        title: 'الموهيتو',
        image: '../../../img/list/mohi.png',
        selected: false,
        items: [
            {
                id: 15,
                title: 'كركديه',
                description: '',
                image: '../../img/items.png',
                price: 15,
                calories: 0,
                selected: true,
            },
            {
                id: 1,
                title: 'موهيتو رمان',
                description: 'سفن أب - كودرد - صودا',
                image: '../../img/food/asr.png',
                price: '15',
                calories: 110,
                selected: false,
            },
            {
                id: 2,
                title: 'موهيتو خوخ',
                description: 'سفن أب - كودرد - صودا',
                image: '../../img/food/kuhk.png',
                price: '15',
                calories: 110,
                selected: false,
            },
            {
                id: 3,
                title: 'موهيتو فراولة',
                description: 'سفن أب - كودرد - صودا',
                image: '../../img/food/asr.png',
                price: '15',
                calories: 110,
                selected: false,
            },
            {
                id: 4,
                title: 'موهيتو توت ازرق',
                description: 'سفن أب - كودرد - صودا',
                image: '../../img/food/bleuberry.jpeg',
                price: '15',
                calories: 110,
                selected: false,
            },
            {
                id: 5,
                title: 'موهيتو توت أحمر',
                description: 'سفن أب - كودرد - صودا',
                image: '../../img/food/asr.png',
                price: '15',
                calories: 110,
                selected: false,
            },
            {
                id: 6,
                title: 'موهيتو بطيخ',
                description: 'سفن أب - كودرد - صودا',
                image: '../../img/food/btehk.png',
                price: '15',
                calories: 110,
                selected: false,
            },
            {
                id: 7,
                title: 'موهيتو مكس',
                description: 'سفن أب - كودرد - صودا',
                image: '../../img/food/asr.png',
                price: '15',
                calories: 110,
                selected: false,
            },
            {
                id: 8,
                title: 'هولستن',
                description: 'فراوله - رمان - عنب',
                image: '../../img/items.png',
                price: '15',
                calories: 142,
                selected: false,
            },
            {
                id: 9,
                title: 'موهيتو ليلة لقانا',
                description: 'سفن أب - كودرد - صودا',
                image: '../../img/food/signature.jpeg',
                price: '15',
                calories: 110,
                selected: false,
            },
            {
                id: 10,
                title: 'كوكاكولا',
                description: 'علبة زجاجية',
                image: '../../img/food/cockaclass.png',
                price: '6',
                calories: 194,
                selected: false,
            },
            {
                id: 11,
                title: 'سفن أب',
                description: '',
                image: '../../img/food/sfn.jpeg',
                price: '6',
                calories: 142,
                selected: false,
            },
        ]
    },
    {
        id: 6,
        title: 'العصائر',
        image: '../../../img/list/juc.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'كوكتيل',
                description: '',
                image: '../../img/food/koktil.png',
                price: 15,
                calories: 83,
                selected: false,
            },
            {
                id: 2,
                title: 'أفوكادو',
                description: '',
                image: '../../img/food/afokado.png',
                price: 15,
                calories: 151,
                selected: false,
            },
            {
                id: 3,
                title: 'كيوي',
                description: '',
                image: '../../img/food/kiwi.png',
                price: 15,
                calories: 64,
                selected: true,
            },
            {
                id: 4,
                title: 'بطيخ',
                description: '',
                image: '../../img/food/b6e5.png',
                price: 15,
                calories: 45,
                selected: true,
            },
            {
                id: 5,
                title: 'رمان',
                description: '',
                image: '../../img/food/roman.png',
                price: 15,
                calories: 47,
                selected: true,
            },
            {
                id: 6,
                title: 'أناناس',
                description: '',
                image: '../../img/food/ananas.png',
                price: 15,
                calories: 62,
                selected: true,
            },
            {
                id: 7,
                title: 'شمام',
                description: '',
                image: '../../img/food/shmam.png',
                price: 15,
                calories: 75,
                selected: true,
            },
            {
                id: 8,
                title: 'تفاح',
                description: '',
                image: '../../img/food/tofa7.png',
                price: 15,
                calories: 65,
                selected: true,
            },
            {
                id: 9,
                title: 'فراولة',
                description: '',
                image: '../../img/food/fraolh.png',
                price: 15,
                calories: 51,
                selected: true,
            },
            {
                id: 10,
                title: 'فراولة حليب',
                description: '',
                image: '../../img/food/fraolh.png',
                price: 15,
                calories: 83,
                selected: true,
            },
            {
                id: 11,
                title: 'مانجو',
                description: '',
                image: '../../img/food/mango.png',
                price: 15,
                calories: 64,
                selected: true,
            },
            {
                id: 12,
                title: 'برتقال',
                description: '',
                image: '../../img/food/bort8al.png',
                price: 15,
                calories: 42,
                selected: true,
            },
            {
                id: 13,
                title: 'برتقال نعناع',
                description: '',
                image: '../../img/food/bort8al.png',
                price: 15,
                calories: 47,
                selected: true,
            },
            {
                id: 14,
                title: 'ليمون',
                description: '',
                image: '../../img/food/limon3.png',
                price: 15,
                calories: 30,
                selected: true,
            },
            {
                id: 15,
                title: 'ليمون نعناع',
                description: '',
                image: '../../img/food/limon3.png',
                price: 15,
                calories: 34,
                selected: true,
            },
            {
                id: 16,
                title: 'موز بالحليب',
                description: '',
                image: '../../img/food/mozmilk.png',
                price: 15,
                calories: 80,
                selected: true,
            },
            {
                id: 17,
                title: 'جوافة',
                description: '',
                image: '../../img/food/jawafh.png',
                price: 15,
                calories: 66,
                selected: true,
            },
        ]
    },
    {
        id: 7,
        title: 'الكوكتيلات',
        image: '../../../img/list/kuk.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'شمبانيا سعودي',
                description: '',
                image: '../../img/food/koktils.png',
                price: 20,
                calories: 95,
                selected: false,
            },
            {
                id: 2,
                title: 'عوار قلب',
                description: '',
                image: '../../img/food/koktils.png',
                price: 15,
                calories: 130,
                selected: false,
            },
            {
                id: 3,
                title: 'كوكتيل منعش',
                description: '',
                image: '../../img/food/koktils.png',
                price: 15,
                calories: 128,
                selected: true,
            },
            {
                id: 4,
                title: 'كوكتيل عسولة',
                description: '',
                image: '../../img/food/koktils.png',
                price: 15,
                calories: 97,
                selected: true,
            },
            {
                id: 5,
                title: 'كوكتيل عرايسي',
                description: '',
                image: '../../img/food/koktils.png',
                price: 15,
                calories: 120,
                selected: true,
            },
            {
                id: 6,
                title: 'أصفهاني',
                description: '',
                image: '../../img/food/koktils.png',
                price: 15,
                calories: 183,
                selected: true,
            },
            {
                id: 7,
                title: 'كوكتيل طبقات',
                description: '',
                image: '../../img/food/koktils.png',
                price: 18,
                calories: 69,
                selected: true,
            },
            {
                id: 8,
                title: 'كوكتيل عنب',
                description: '',
                image: '../../img/food/koktils.png',
                price: 15,
                calories: 79,
                selected: true,
            },
            {
                id: 9,
                title: 'كوكتيل ليلة لقانا',
                description: '',
                image: '../../img/food/koktils.png',
                price: 20,
                calories: 81,
                selected: true,
            },
        ]
    },
    {
        id: 8,
        title: 'ميلك شيك',
        image: '../../../img/list/milk.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'أوريو',
                description: '',
                image: '../../img/food/milkchik.png',
                price: 15,
                calories: 95,
                selected: false,
            },
            {
                id: 2,
                title: 'لوتس',
                description: '',
                image: '../../img/food/milkchik.png',
                price: 15,
                calories: 130,
                selected: false,
            },
            {
                id: 3,
                title: 'سنكرز',
                description: '',
                image: '../../img/food/milkchik.png',
                price: 15,
                calories: 128,
                selected: true,
            },
            {
                id: 4,
                title: 'فانيلا',
                description: '',
                image: '../../img/food/milkchik.png',
                price: 15,
                calories: 97,
                selected: true,
            },
            {
                id: 5,
                title: 'شوكلاته',
                description: '',
                image: '../../img/food/milkchik.png',
                price: 15,
                calories: 120,
                selected: true,
            },
            {
                id: 6,
                title: 'نوتيلا',
                description: '',
                image: '../../img/food/milkchik.png',
                price: 15,
                calories: 183,
                selected: true,
            },
            {
                id: 7,
                title: 'فراولة',
                description: '',
                image: '../../img/food/milkchik.png',
                price: 15,
                calories: 69,
                selected: true,
            },
            {
                id: 8,
                title: 'كت كات',
                description: '',
                image: '../../img/food/milkchik.png',
                price: 15,
                calories: 79,
                selected: true,
            },
        ]
    },
    {
        id: 9,
        title: 'السموثي',
        image: '../../../img/list/smoth.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'توت',
                description: '',
                image: '../../img/food/smoothe.png',
                price: 15,
                calories: 75,
                selected: false,
            },
            {
                id: 2,
                title: 'فراولة',
                description: '',
                image: '../../img/food/smoothe.png',
                price: 15,
                calories: 90,
                selected: false,
            },
            {
                id: 3,
                title: 'باشن فروت',
                description: '',
                image: '../../img/food/smoothe.png',
                price: 15,
                calories: 97,
                selected: false,
            },
            {
                id: 4,
                title: 'مكس خوخ',
                description: '',
                image: '../../img/food/smoothe.png',
                price: 15,
                calories: 102,
                selected: false,
            },
            {
                id: 5,
                title: 'برتقال',
                description: '',
                image: '../../img/food/smoothe.png',
                price: 15,
                calories: 98,
                selected: false,
            },
        ]
    },
    {
        id: 10,
        title: 'البرجر',
        image: '../../../img/list/burger.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'برجر حاشي',
                description: 'وجبة',
                image: '../../img/food/sayad.jpg',
                price: 26,
                calories: 525,
                selected: false,
            },
            {
                id: 2,
                title: 'برجر لحم',
                description: 'وجبة',
                image: '../../img/food/mashroom.jpg',
                price: 24,
                calories: 580,
                selected: false,
            },
            {
                id: 3,
                title: 'برجر دجاج جريل',
                description: 'وجبة',
                image: '../../img/food/maqli.jpg',
                price: 23,
                calories: 410,
                selected: false,
            },
            {
                id: 4,
                title: 'كودو دجاج',
                description: 'وجبة',
                image: '../../img/food/kudo.jpg',
                price: 23,
                calories: 460,
                selected: false,
            },
            {
                id: 5,
                title: 'تشيكن فرايز',
                description: 'طبق تشيكن فرايز',
                image: '../../img/food/chekin.webp',
                price: 15,
                calories: 1394,
                selected: false,
            },
            {
                id: 6,
                title: 'بطاطس جبن لحم',
                description: 'مع صوص',
                image: '../../img/food/fraizl7m.png',
                price: 12,
                calories: 470,
                selected: false,
            },
            {
                id: 7,
                title: 'بطاطس جبن',
                description: 'مع الصوص',
                image: '../../img/food/btats-jbn.png',
                price: 12,
                calories: 275,
                selected: false,
            },
            {
                id: 8,
                title: 'بطاطس مقلية',
                description: 'مع الصوص',
                image: '../../img/food/btats-m8li.webp',
                price: 12,
                calories: 275,
                selected: false,
            },
            {
                id: 9,
                title: 'صوص حار',
                description: '',
                image: '../../img/food/sos-hot.png',
                price: 2,
                calories: 36,
                selected: false,
            },
            {
                id: 10,
                title: 'صوص تشيكن',
                description: '',
                image: '../../img/food/cheese-Sauce.png',
                price: 2,
                calories: 36,
                selected: false,
            },
            {
                id: 11,
                title: 'صوص لحم',
                description: '',
                image: '../../img/food/sos-l7m.png',
                price: 2,
                calories: 36,
                selected: false,
            },
        ]
    },
    {
        id: 11,
        title: 'قطع فواكة',
        image: '../../../img/list/fokh.png',
        selected: false,
        items: [
            {
                id: 1,
                title: ' مانجو + فراولة',
                description: '',
                image: '../../img/food/foakh.png',
                price: 18,
                calories: 140,
                selected: false,
            },
            {
                id: 2,
                title: 'رمان + أناناس',
                description: '',
                image: '../../img/food/foakh.png',
                price: 18,
                calories: 85,
                selected: false,
            },
            {
                id: 3,
                title: 'عنب + كيوي + موز',
                description: '',
                image: '../../img/food/foakh.png',
                price: 18,
                calories: 120,
                selected: false,
            },
            {
                id: 4,
                title: 'رمان + عنب',
                description: '',
                image: '../../img/food/foakh.png',
                price: 18,
                calories: 65,
                selected: false,
            },
            {
                id: 5,
                title: 'فخفخينا',
                description: '',
                image: '../../img/food/foakh.png',
                price: 22,
                calories: 95,
                selected: false,
            },
            {
                id: 6,
                title: 'باور القلب',
                description: '',
                image: '../../img/food/foakh.png',
                price: 18,
                calories: 26,
                selected: false,
            },
            {
                id: 7,
                title: 'سلطة فواكة',
                description: '',
                image: '../../img/food/foakh.png',
                price: 25,
                calories: 160,
                selected: false,
            },
        ]
    },
    {
        id: 12,
        title: 'الحلا',
        image: '../../../img/list/soit.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'تشيز كيك توت',
                description: '',
                image: '../../img/food/kiktot.png',
                price: 18,
                calories: 0,
                selected: false,
            },
            {
                id: 2,
                title: 'تشيز كيك الزعفران',
                description: '',
                image: '../../img/food/kikz3fran.png',
                price: 18,
                calories: 300,
                selected: false,
            },
            {
                id: 3,
                title: 'كيكة البندق',
                description: '',
                image: '../../img/food/kikbond8.png',
                price: 18,
                calories: 430,
                selected: true,
            },
            {
                id: 4,
                title: 'كيكة العسل',
                description: '',
                image: '../../img/food/kik3sl.png',
                price: 18,
                calories: 530,
                selected: true,
            },
            {
                id: 5,
                title: 'كيكة ريد فيلفت',
                description: '',
                image: '../../img/food/kikred.png',
                price: 18,
                calories: 580,
                selected: true,
            },
            {
                id: 6,
                title: 'تشيز كيك سنكرز ',
                description: '',
                image: '../../img/food/kiksnkrs.png',
                price: 18,
                calories: 510,
                selected: true,
            },
            {
                id: 7,
                title: 'بان كيك',
                description: '',
                image: '../../img/food/pankik.jpg',
                price: 18,
                calories: 510,
                selected: true,
            },
            {
                id: 8,
                title: 'وافل',
                description: '',
                image: '../../img/food/wafeell.jpeg',
                price: 18,
                calories: 510,
                selected: true,
            },
            {
                id: 9,
                title: 'كريب',
                description: '',
                image: '../../img/food/kripchoklit.jpeg',
                price: 18,
                calories: 510,
                selected: true,
            },
        ]
    },
    {
        id: 13,
        title: 'الشيشة',
        image: '../../../img/list/shisha.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'معسل تفاحتين فاخر',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: false,
            },
            {
                id: 2,
                title: 'معسل تفاحتين نخلة',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: false,
            },
            {
                id: 3,
                title: 'معسل مكس [ فاخر + نخلة ]',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: true,
            },
            {
                id: 4,
                title: 'معسل عنب توت',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: true,
            },
            {
                id: 5,
                title: 'معسل عنب نعناع',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: true,
            },
            {
                id: 6,
                title: 'معسل ليمون نعناع',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: true,
            },
            {
                id: 7,
                title: 'معسل علكة',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: true,
            },
            {
                id: 8,
                title: 'معسل نعناع',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: true,
            },
            {
                id: 9,
                title: 'معسل خوخ',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: true,
            },
            {
                id: 10,
                title: 'معسل بطيخ',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: true,
            },
            {
                id: 11,
                title: 'معسل فراولة',
                description: 'تغيير راس بـ20 ريال',
                image: '../../img/food/Shisha.jpg',
                price: 30,
                calories: 0,
                selected: true,
            },
        ]
    },
    {
        id: 14,
        title: 'آيسكريم',
        image: '../../../img/list/ice-cream.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'آيسكريم كوفي',
                description: '',
                image: '../../img/food/icecreem.png',
                price: 15,
                calories: 120,
                selected: false,
            },
            {
                id: 2,
                title: 'آيسكريم فراولة',
                description: '',
                image: '../../img/food/icecreem.png',
                price: 15,
                calories: 89,
                selected: false,
            },
            {
                id: 3,
                title: 'آيسكريم فانيلا',
                description: '',
                image: '../../img/food/icecreem.png',
                price: 15,
                calories: 95,
                selected: true,
            },
            {
                id: 4,
                title: 'آيسكريم بستاشيو',
                description: '',
                image: '../../img/food/icecreem.png',
                price: 15,
                calories: 110,
                selected: true,
            },
        ]
    },
]

export default data 