import { BsX, BsSearch } from "react-icons/bs"

const SearchBar = ({  handleSearch,setSearchQuery, searchQuery}) => {
  return (
    <form
      name="myForm"
      className="mx-4 mt-4 flex gap-2 relative"
      onSubmit={handleSearch}
    >
      <input
        onChange={(e)=>setSearchQuery(e.target.value)}
        value={searchQuery}
        autoComplete="off"
        type="text"
        placeholder="البحث . . ."
        name="search"
        className="outline-none border border-gray-200 focus:border-primaryGreen-500 py-1.5 px-4 flex-grow rounded-md bg-white focus:bg-white dark:bg-gray-700 dark:placeholder:text-white dark:text-white"
      />
      {searchQuery.length>0 ? (
        <button
          className="w-10 h-10 flex items-center justify-center rounded-md text-primaryGreen-400 dark:text-gray-100 absolute left-1"
          onClick={()=>setSearchQuery('')}
          type='button'
          title="انهاء البحث"
        >
          <BsX className="text-2xl" />
        </button>
      ) : (
        <button
          className="w-10 h-10 flex items-center justify-center rounded-md text-gray-900 dark:text-gray-100 absolute left-1"
          title="البحث"
        >
          <BsSearch />
        </button>
      )}
    </form>
  )
}

export default SearchBar
